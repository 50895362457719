import Swiper from 'swiper';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

export class Slider
{
    constructor()
    {
        document.querySelectorAll('.c-slider--on').forEach(s => {
            const swiper = new Swiper(s, {
                modules: [Pagination, Autoplay],
                autoplay: {
                    delay: 4000,
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
            });
        })

        document.querySelectorAll('.c-infinite-slider').forEach(s => {
            const swiper = new Swiper(s, {
                modules: [Autoplay],
                loop: true,
                spaceBetween: 70,
                autoplay: {
                    delay: 0,
                    pauseOnMouseEnter: true,        // added
                },
                speed: 5000,
                slidesPerView: 1,

                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    // when window width is >= 480px
                    480: {
                        slidesPerView: 3,
                        spaceBetween: 30
                    },
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 5,
                        spaceBetween: 70,
                    }
                }
            });
        })
    }
}
