import {gsap} from "gsap";

export class Header {
    burgerListener = null;
    navListener = null;

    constructor()
    {
        this.header = document.querySelector('header.header')
        this.banner = this.header.querySelector('.banner .container')
        this.nav = this.banner.querySelector('.nav-wrapper')
        this.topbar = this.header.querySelector('.topbar')

          window.addEventListener('load', evt => {
            const topbarHeight = this.topbar.offsetHeight;

            let tlt = gsap.timeline({paused: true});

            tlt.to(this.header, {top: -topbarHeight})

            window.addEventListener('scroll', evt => {
              if (window.scrollY > (topbarHeight + 50)) {
                this.header.classList.add('is-visible')
                tlt.play()
              } else {
                this.header.classList.remove('is-visible')
                tlt.reverse()
              }
            });
          })

      let mm = gsap.matchMedia(),
        breakPoint = 992;

      mm.add({
        isDesktop: `(min-width: ${breakPoint}px) and (prefers-reduced-motion: no-preference)`,
        isMobile: `(max-width: ${breakPoint - 1}px) and (prefers-reduced-motion: no-preference)`
      }, (context) => {
        let { isDesktop, isMobile } = context.conditions

        if (isMobile) {
          this.tl = gsap.timeline({paused:true});
          this.animation()

          this.#burgerListener()

          this.#navListener()
        }

        return () => {
        }
      });
    }

    #burgerListener()
    {
      if (this.burgerListener) {
        return
      }

      const burger = document.querySelector('#burger')

      if (burger) {
        this.burgerListener = true;
        burger.addEventListener('click', evt => {
          evt.preventDefault()

          burger.classList.toggle('active')
          this.banner.classList.toggle('expanded')
          document.querySelector('html').classList.toggle('expanded')
          document.body.classList.toggle('expanded')

          if (this.banner.classList.contains('expanded')) {
            this.tl.play()
          } else {
            this.tl.reverse()
          }
        })
      }
    }

    #navListener()
    {
      if (this.navListener) {
        return;
      }

      if (this.nav) {
        this.navListener = true;
        this.nav.querySelectorAll('.menu-item.menu-item-has-children > a').forEach(item => {
          const tl = gsap.timeline({paused: true});
          const parent = item.closest('.menu-item');
          this.animationItems(parent, tl)

          item.addEventListener('click', evt => {
            evt.preventDefault()
            parent.classList.toggle('active')

            if (parent.classList.contains('active')) {
              tl.play()
            } else {
              tl.reverse()
            }
          })
        })
      }
    }

    animation()
    {
        this.tl.to(this.nav, {x: 0, duration: .5, ease: 'power4'})
    }

    animationItems(parentItem, tl)
    {
        const container = parentItem.querySelector('.sub-menu')
        if (container) {
            const menu_items = container.querySelectorAll(':scope > .menu-item')
            const div = document.createElement('div')
            div.classList.add('arrow-container')

          document.querySelector('.nav .menu-item-has-children').removeEventListener('click', () => {})
            document.querySelector('.nav .menu-item-has-children').addEventListener('click', el => {
                  document.querySelector('.nav .menu-item-has-children .sub-menu').classList.toggle('active')
            });


            container.appendChild(div)
            container.querySelector('.arrow-container').innerHTML = '<svg class="arrow" width="56" height="51" viewBox="0 0 56 51" fill="currentColor" xmlns="http://www.w3.org/2000/svg">\n' +
            '  <g clip-path="url(#clip0)">\n' +
            '    <path d="M53.2349 0.712891H4.25654V9.78049L37.7273 9.40268L2.57654 43.28L9.55501 50.0807L44.318 15.8256L43.9303 48.4435H53.2349V0.712891Z" fill="currentColor"></path>\n' +
            '  </g>\n' +
            '  <defs>\n' +
            '    <clipPath id="clip0">\n' +
            '      <rect width="56" height="50.3755" fill="currentColor"></rect>\n' +
            '    </clipPath>\n' +
            '  </defs>\n' +
            '</svg>'
        }
    }

}
