import {CountUp} from "countup.js";

export class Count
{
    constructor()
    {
        document.querySelectorAll('*[data-count]').forEach((count) => {
            const tl = new CountUp(count, count.dataset.count, {
              separator: '',
            });

          // on intersection observer
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        tl.start();
                        observer.unobserve(count);
                    }
                });
            });
        observer.observe(count);
        });
    }
}

